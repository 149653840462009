import Vue from "vue";
import { HTTP, baseUploadURL, baseURL } from "./axios";
import moment from "moment";

Vue.prototype.$baseUploadURL = baseUploadURL;
Vue.prototype.$baseURL = baseURL;

Vue.prototype.$moment = moment;

Vue.prototype.$http = HTTP;

Vue.prototype.$eventHub = new Vue();

Vue.prototype.$changeRtl = (lang: any) => {
  localStorage.lang = lang;
  const d: any = document.getElementById('body');

  d.attributes.class.value = lang === 'en' ? 'ltr' : '';
};

if (localStorage.lang) {
  Vue.prototype.$changeRtl(localStorage.lang);
}

if (!localStorage.lang) {
  localStorage.lang = "ar";
}
Vue.prototype.$getByLang = (text: any) => {
  if (localStorage.lang && text && text[localStorage.lang]) {
    return text[localStorage.lang];
  } else {
    return "";
  }
};

Vue.prototype.$getBG = () => {
  const list = ["#FFEECE", "#E0EFF6", "#CAF3E5", "#EEEBFF"];
  const index = Math.floor(Math.random() * list.length);
  return list[index];
};


Vue.prototype.$textSlice = (text: any, limit = 10) => {
  if (text && text.length > limit) {
    return text.slice(0, limit) + "...";
  } else {
    return text;
  }
};


Vue.prototype.$codePadding = (text, length = 2) => {
  return String(text).padStart(length, '0');
};

Vue.prototype.$formatDate = (date: any) => {
  return moment(date).format("DD/MM/YYYY");
};



Vue.prototype.$formatDateOffers = (date: any) => {
  return moment(date).format("MM/DD/YYYY");
};


Vue.prototype.$delevryDate= (date: any) => {
  return moment(date).add(7, 'days').format("MM/DD/YYYY");
};


Vue.prototype.$durationFormatFrom = (value) => {
  if (value) {
    const lang = localStorage.lang ? localStorage.lang : 'en';
    moment.locale(lang);
    const duration = moment(String(value));
    // console.log(duration)
    return duration.fromNow();
  } else {
    return '';
  }
};

Vue.prototype.$file2base64 = (
  ev: any,
  cb: (arg0: string | ArrayBuffer | null) => void,
) => {
  const file = ev.target.files[0];
  const reader = new FileReader();
  reader.onload = (e: ProgressEvent) => {
    const content = (e.target as FileReader).result;
    cb(content);
  };
  reader.readAsDataURL(file);
};