<template>
  <div class="right-profil text-center">
    
    <img class="from-img" v-if="image" :src="image.startsWith('data') ? image : $baseUploadURL + image" />
    <img class="from-img" v-else src="/images/user.png" />
    <h5 class="profil-name">
      {{ user }}
    </h5>
    <div class="from-list">
      <ul>
        <li class="content-profile" :class="active == 'myprofile' ? 'active' : ''">
          <router-link to="/myprofile">
            {{ $t("my Account") }}
          </router-link>
        </li>
        <li class="content-profile" :class="active == 'orders' ? 'active' : ''">
          <router-link to="/orders">{{ $t("Requests") }} </router-link>
        </li>
        <li class="content-profile" :class="active == 'myfavorite' ? 'active' : ''">
          <router-link to="/myfavorite">{{
            $t("My favorite products")
            }}</router-link>
        </li>
        <li class="content-profile" :class="active == 'addresses' ? 'active' : ''">
          <router-link to="/address">{{ $t("addresses") }}</router-link>
        </li>
        <li class="content-profile" :class="active == 'helpcenter' ? 'active' : ''">
          <router-link to="/helpcenter">{{ $t("Help Center") }}</router-link>
        </li>
        <li class="content-profile log-out" @click="logout" style="cursor: pointer">
          {{ $t("Log out") }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MySidebar",
  props: {
    active: String,
    user: String,
    image: String,
  },
  methods: {
    logout() {
      delete localStorage.homeHere;

      location.reload();
    },
  },
};
</script>
