import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from "../layouts/layout.vue";
import LayoutNoFooter from "../layouts/layout-nofooter.vue";

import Home from "../views/Home.vue";

Vue.use(VueRouter);

const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.homeHere) {
    next();
    return;
  }
  next("/login");
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.homeHere) {
    next();
    return;
  }
  next("/");
};
const ifPrivacyPolicy = (to: any, from: any, next: any) => {
  
  next("/page/policy");
};

const routes: RouteConfig[] = [
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",

    beforeEnter: ifPrivacyPolicy,
    component: () => import("../views/auth/login.vue"),
  },

  {
    path: "/login",
    name: "login",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/login.vue"),
  },
  {
    path: "/regstar",
    name: "regstar",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/regstar.vue"),
  },

  {
    path: "/activate",
    name: "activate",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/activate.vue"),
  },

  {
    path: "/activateUser",
    name: "activateUser",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/activateUser.vue"),
  },

  {
    path: "/contactUs",
    name: "contactUs",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/contactUsForApple.vue"),
  },
  {
    path: "/vendorsRequests",
    name: "vendorsRequests",
    component: () => import("../views/auth/vendorsRequests.vue"),
    
  },

  {
    path: "/forgetPassword",
    name: "forgetPassword",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/forgetPassword.vue"),
  },
  {
    path: "/passwordConfirmation",
    name: "passwordConfirmation",

    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/passwordConfirmation.vue"),
  },

  {
    path: "/auth/:type/redirect",
    name: "authRedirect",
    beforeEnter: ifNotAuthenticated,
    component: () => import("../views/auth/auth_redirect.vue"),
  },
  {
    path: "/doneCart2/:id",
    name: "doneCart2",
    component: () => import("../views/cart/doneCart2.vue"),
  },

  
  {
    path: "/checkout/failure",
    name: "failure",
    component: () => import("../views/cart/failure.vue"),
  },
  {
    path: "/checkout/cancel",
    name: "cancel",
    component: () => import("../views/cart/failure.vue"),
  },
  {
    path: "/payments/tamarapay",
    name: "cancel",
    component: () => import("../views/cart/tamarapay.vue"),
  },





  // myprofile
  {
    path: "/profile",
    name: "LayoutNoFooter",
    component: LayoutNoFooter,
    children: [
      {
        path: "/myprofile",
        name: "myprofile",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/myprofile.vue"),
      },

      {
        path: "/editprofile",
        name: "editprofile",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/editprofile.vue"),
      },
      {
        path: "/editNumber",
        name: "editNumber",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/editnumber.vue"),
      },
      {
        path: "/changepassword",
        name: "changepassword",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/changepassword.vue"),
      },
      {
        path: "/address",
        name: "address",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/address.vue"),
      },

      {
        path: "/addaddress",
        name: "addaddress",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/addaddress.vue"),
      },
      {
        path: "/addmap",
        name: "addmap",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/addmap.vue"),
      },
      {
        path: "/editaddress/:id",
        name: "editaddress",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/editaddress.vue"),
      },
      {
        path: "/editmap/:id",
        name: "editmap",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/editmap.vue"),
      },
      {
        path: "/helpcenter",
        name: "helpcenter",
        component: () => import("../views/profile/helpcenter.vue"),
      },
      {
        path: "/termsAndConditions",
        name: "termsAndConditions",
        component: () => import("../views/profile/termsAndConditions.vue"),
      },
      {
        path: "/myfavorite",
        name: "myfavorite",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/myfavorite.vue"),
      },
      // fixed page
      {
        path: "/orders",

        beforeEnter: ifAuthenticated,
        name: "orders",
        component: () => import("../views/profile/orders.vue"),
      },
      {
        path: "/ordersDone",
        name: "ordersDone",
        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/ordersDone.vue"),
      },

      {
        path: "/orderDetails/:id",
        name: "orderDetails",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/profile/orderDetails.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/products/:id",
        name: "products",
        component: () => import("../views/products/products.vue"),
      },
      {
        path: "/categories/:id",
        name: "categories",
        component: () => import("../views/products/categories.vue"),
      },
      {
        path: "/shops/:id",
        name: "shops",
        component: () => import("../views/products/shops.vue"),
      },

      {
        path: "/allshops",
        name: "allshops",
        component: () => import("../views/products/allshops.vue"),
      },

      {
        path: "/search",
        name: "search",
        component: () => import("../views/products/search.vue"),
      },

      {
        path: "/offers/:id",
        name: "offers",
        component: () => import("../views/products/offers.vue"),
      },

      {
        path: "/product/:id",
        name: "product",
        component: () => import("../views/products/productDetails.vue"),
      },

      {
        path: "/cart",
        name: "cart",
        component: () => import("../views/cart/cart.vue"),
      },
      {
        path: "/shippingAddress",
        name: "shippingAddress",
        component: () => import("../views/cart/shippingAddress.vue"),
      },
      {
        path: "/shippingAddress/addShippingAddress",
        name: "addShippingAddress",
        component: () => import("../views/cart/addShippingAddress.vue"),
      },
      {
        path: "/shippingAddress/addMap",
        name: "addMap",
        component: () => import("../views/cart/addMap.vue"),
      },

      {
        path: "/doneCart/:id",
        name: "doneCart",
        component: () => import("../views/cart/doneCart.vue"),
      },

      {
        path: "/checkout/order-received/:id",
        name: "orderReceived",
        component: () => import("../views/cart/doneCart.vue"),
      },
      {
        path: "/payCart/:id/:itemId/:type",
        name: "payCart",
        component: () => import("../views/cart/payCart.vue"),
      },

      {
        path: "/packages",
        name: "packages",
        component: () => import("../views/products/packages.vue"),
      },
      {
        path: "/package/:id",
        name: "package",
        component: () => import("../views/products/package.vue"),
      },

      {
        path: "/page/:slug",
        name: "page",
        component: () => import("../views/page.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
