import Vue from "vue";

import "bootstrap";

import "bootstrap/dist/css/bootstrap.css";



import "normalize.css";


// Vue.use(bootstrap)
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput);

// Install PrimeVue
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
Vue.use(PrimeVue, { ripple: true });
Vue.use(ToastService);
Vue.use(ConfirmationService);
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Toast from "primevue/toast";
import Menu from "primevue/menu";
import Checkbox from "primevue/checkbox";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

import MegaMenu from "primevue/megamenu";
import RadioButton from "primevue/radiobutton";
import Slider from "primevue/slider";
import Paginator from "../components/paginator";
import Dialog from "primevue/dialog";
import Sidebar from "primevue/sidebar";
import Avatar from "primevue/avatar";
import ProgressSpinner from "primevue/progressspinner";





import Menubar from 'primevue/menubar';

Vue.component("Menubar", Menubar);


Vue.component("Toast", Toast);
Vue.component("InputText", InputText);
Vue.component("Password", Password);
Vue.component("Menu", Menu);
Vue.component("Checkbox", Checkbox);
Vue.component("Card", Card);
Vue.component("Dropdown", Dropdown);
Vue.component("Accordion", Accordion);
Vue.component("AccordionTab", AccordionTab);
Vue.component("MegaMenu", MegaMenu);
Vue.component("RadioButton", RadioButton);
Vue.component("Slider", Slider);
Vue.component("Paginator", Paginator);
Vue.component("Dialog", Dialog);
Vue.component("Sidebar", Sidebar);
Vue.component("Avatar", Avatar);
Vue.component("ProgressSpinner", ProgressSpinner);

// My component

import MySidebar from "../components/sidebar.vue";
Vue.component("MySidebar", MySidebar);

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAkM_lBluLCrr71FPsTunK_IgUoZchEX44",
    libraries: "places,drawing,visualization", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    v: "3.26",
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  //   autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);



import VueHead from "vue-head";

Vue.use(VueHead, {
  separator: '-',
  complement: 'Here Home | هير هوم'
})