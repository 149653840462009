import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './helpers';
import './assets/css/style.css';
import './assets/css/style_en.css';
import i18n from './i18n/i18n';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);



Vue.config.productionTip = false;
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');